
import police from './police.jpg';
import themis from './Themis.jpg';
import phone from './phone.png';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

export default App;

function App() {
 const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

  return (
    <div className="App">
      <div className='contentWrap'>
      <div className='backthemisDesktop'></div>
      <div className='content'>
      <div className='back-themis'>
        <div>
        <div className='title'>
          <div className="titleName">
            <div className='titleNameContent intitule'>Antoine CHAUDEY</div>
            <div className="step"></div>
            </div>
          <div className="titleFonction">
            <div className='titleFonctionContent intitule'>Avocat</div>
            <div className="step"></div>
            </div>
        </div>
        </div>
      </div>
      <div className='redStripeContainer'>
        <div className="redStripe"></div>
        {!isDesktop ? <Portrait isDesktop={isDesktop}/> : <Themis isDesktop={isDesktop}/>}
        <div className="deviseContainer">
          <div className="devise">
            <p>Défense pénale, <br/> Contentieux <br/> & Libertés</p>
          </div>
        </div>
      </div>
      <div className='mainContainer'>
        {!isDesktop ? <Presentation isDesktop={isDesktop}/> : <PresentationDesktop isDesktop={isDesktop}/>}
        {!isDesktop && <VideoContainer/>}
        {!isDesktop ? <DomainesDIntervention isDesktop={isDesktop}/>  : <DomainesDInterventionDesktop isDesktop={isDesktop}/>}
        {!isDesktop && <Police/>}
        {!isDesktop && <Honoraires/>}
        {!isDesktop && <Contact/>}
      </div>
      </div>
      </div>
      <footer>Mentions légales :  Editeur : Antoine CHAUDEY – Avocat au Barreau de LILLE © 2023 – Membre d’une association agréée – Le règlement par chèque est accepté - SIRET : 80894846700028 – TVA : FR85808948467 – Hébergeur : Netlify, Inc., 44 Montgomery Street, Suite 300, San Francisco, California 94104.</footer>
    </div>
  );
}

function Portrait(props) {return(<>
    <div className="portraitContainer">
      <div className={!props.isDesktop ? 'portrait' : ''}></div>
        <div className="stepPortrait"></div>
    </div>
</>)}

function Themis(props) {return(<>
  <div className="themisContainer">
    <div className='portraitDesktop'></div>
      <div className="stepPortrait"></div>
  </div>
</>)}

function ContactInfo(){return(<>
        <div className="subTitleName">
                    Antoine CHAUDEY
                    </div>
            <div className="adresse">
            Avocat au Barreau de LILLE<br/>
            <a href="https://goo.gl/maps/WtsgBbjf4A9Tnhdv5" alt="Adresse Avocat Lille Roubaix Tourcoing">113, Avenue Jean Lebas – 59100 ROUBAIX</a>
            <br/><br/>
            Métro : Gare Jean Lebas
            <br/>
            <div className="phoneContainer">
              <div className="phoneLogoContainer">
              <img src={phone} className="phoneLogo" alt="logo"/>
              </div>
              <div className="phone">: 06 47 26 14 91</div>
            </div>
            Fax. 03 20 24 19 92 <br/><br/>
            <a href="a.chaudey@avocats-abg.fr">a.chaudey@avocats-abg.fr</a>
            </div>
</>)}

function ContactTitle(){return(<>
CONTACT
</>)}

function Contact(){return(
  <>
  <div className="presentationStickyContainer contact">
          <div className="presentationTitleContainer">
              <div className='bulletTitleContainer'>
                <div className='bulletTitle'></div>
              </div>
              <div className="presentationTitle">
              <ContactTitle/>
              </div>
          </div>
          <div className="presentationContainer">
            <ThemisImg/>
            <ContactInfo/>
          </div>
        </div>
  </>
)}

function ThemisImg(){return(
  <>
    <img src={themis} className="pictureThemis" alt="Themis, déesse de la Justice. Dessinée en novembre 2015. Représente une femme tenant une balance symbole de la justice. Le dessin est effectué sur un papier crème au fusain."></img>
  </>
)}



function HonorairesTitle(){return(
  <>HONORAIRES ET AIDE JURIDICTIONNELLE</>
)
}

function HonorairesContent(){return(<>
                <a href="https://www.service-public.fr/particuliers/vosdroits/F15018"> Les honoraires sont librement fixés entre l’avocat et son client </a>, en fonction d’un certain nombre de critères, dont : la situation de fortune du client, la difficulté du dossier, les frais exposés par l’avocat, la notoriété de ce dernier, ou encore la nature de ses diligences. Les honoraires peuvent être forfaitaires, au taux horaire et accompagnés d’un pourcentage en fonction du résultat obtenu. Ils font systématiquement l’objet d’une discussion, et de la régularisation d’une convention.
</>)}

function HonorairesContent2(){return(<>
                Les personnes qui y sont accessibles peuvent bénéficier de <a href="https://www.service-public.fr/particuliers/vosdroits/F18074">l’aide juridictionnelle.</a>
  </>)}

function Honoraires(){return(<>
      <div className="presentationStickyContainer honoraires">
              <div className="presentationTitleContainer">
                  <div className='bulletTitleContainer'>
                    <div className='bulletTitle'></div>
                  </div>
                  <div className="presentationTitle">
                  <HonorairesTitle/>
                  </div>
              </div>
              <div className="presentationContainer">
                <div className="presentation">
                  <HonorairesContent/>
                </div>
                <div className="presentation">
                  <HonorairesContent2/>
                </div>
              </div>
            </div>
      </>)}

function Police(){
  return(
    <div className="policeContainerBackground">
    <div className="picturePoliceContainer">
      <img src={police} className="picturePolice" alt="Affiche humouristique sur le rappel des droits de l'interpellé. Police. Justice. Dessin. Face à la Police je n'ai rien à déclarer"/>
    </div>
  </div>
  )}

function DomainesDInterventionDesktop(props){
  return(<>
      <div className='interventionContainer'>
        <Title isDesktop={props.isDesktop}>
          <DomainesDInterventionTitle isDesktop={props.isDesktop}/>
        </Title>
        <Bloc1 isDesktop={props.isDesktop}>
          <DomainesDInterventionContent1/>
        </Bloc1>
        <SubTitle isDesktop={props.isDesktop}>
          <DomainesDInterventionTiTle2 isDesktop={props.isDesktop}/>
        </SubTitle>
        <Bloc1 isDesktop={props.isDesktop}>
          <DomainesDInterventionContent2/>
        </Bloc1>
        <div className='interventionDesktopSubContainer'>
          <div className='interventionDesktopSubSubContainer'>
            <SubTitle isDesktop={props.isDesktop}>
              <DomainesDInterventionTiTle3 isDesktop={props.isDesktop}/>
            </SubTitle>
            <Bloc1 isDesktop={props.isDesktop}>
              <DomainesDInterventionContent3 />
            </Bloc1>
            <div className='contactDesktopContainer'>
              <Title isDesktop={props.isDesktop}>
                <ContactTitle/>
              </Title>
              <Bloc1 isDesktop={props.isDesktop}>
                <ThemisImg/>
                <ContactInfo isDesktop={props.isDesktop}/>
              </Bloc1>
              <Portrait isDesktop={props.isDesktop}/>
            </div>
          </div>
          <div className='interventionDesktopSubSubContainer'>
            <SubTitle isDesktop={props.isDesktop}>
              <HonorairesTitle isDesktop={props.isDesktop}/>
            </SubTitle>
            <Bloc1 isDesktop={props.isDesktop}>
              <HonorairesContent/>
              <br/>
              <br/>
              <HonorairesContent2/>
            </Bloc1>
            <Police/>
          </div>
        </div>
      </div>
      </>
  ) }

function DomainesDInterventionTitle(props){
  return(<>DOMAINES {!props.isDesktop && <br/>} D'INTERVENTION</>)}

function DomainesDInterventionContent1(){
  return(<> Vous êtes un particulier, un professionel, une entreprise ou une association : 
    </>)}

function DomainesDInterventionTiTle2(props){
  return(<>{!props.isDesktop ? 'Défense Pénale' : 'DEFENSE PENALE'}</>)}

  function DomainesDInterventionContent2(){
    return(<>
          Il vous est reproché d’avoir commis une infraction pénale (contravention, délit, crime). Le cabinet vous assiste au plus près de vos intérêts en garde à vue, en audition libre, mais aussi devant toutes les juridictions : Juge d’instruction, Juge d’application des peines, Tribunal de police, Tribunal correctionnel, comparution immédiate, Cour d’appel, Cour criminelle départementale, Cour d’assises, ou encore dans le cadre d’une Comparution sur reconnaissance préalable de culpabilité ou d’une composition pénale. 
    </>)}

function DomainesDInterventionTiTle3(props){
  return(<>{!props.isDesktop ? 'Assistance des victimes et réparation du préjudice corporel' : 'ASSISTANCE DES VICTIMES ET REPARATION DU PREJUDICE CORPOREL'}</>)}

  function DomainesDInterventionContent3(){
    return(<>
  Vous êtes victime d’une infraction pénale ou d’un accident (violences, vol, escroquerie, accident de la circulation, etc.) Le cabinet vous accompagne lors de chaque étape du processus amiable ou judiciaire (rédaction de plainte, audition devant les services de police, expertise médicale, audience devant les juridictions, discussions avec l’assurance, etc.), avec le souci constant d’être à votre écoute, de vous conseiller au plus près de vos intérêts, et d’obtenir la plus juste indemnisation de votre préjudice.
    </>)}

function DomainesDInterventionContent4(){
  return(<>
          Le cabinet intervient à la fois pour les majeurs, mais aussi pour les mineurs victimes et prévenus devant les juridictions pour mineurs. 
</>)}

function DomainesDInterventionContent5(){
  return(<>
          Le cabinet vous assiste et vous représente dans toute la France.
</>)}

function DomainesDIntervention(props){return (
<div className="presentationStickyContainer">
        <div className="presentationTitleContainer">
            <div className='bulletTitleContainer'>
              <div className='bulletTitle'></div>
            </div>
            <div className="presentationTitle">
              <DomainesDInterventionTitle isDesktop={props.isDesktop}/>
            </div>
        </div>
        <div className="presentationContainer">
          <div className="presentation">
             <DomainesDInterventionContent1/>
          </div>
          <SubTitle isDesktop={props.isDesktop}>
            <DomainesDInterventionTiTle2 isDesktop={props.isDesktop}/>
          </SubTitle>
          <div className="presentation">
            <DomainesDInterventionContent2/>
          </div>
          <SubTitle isDesktop={props.isDesktop}>
            <DomainesDInterventionTiTle3/>
          </SubTitle>
          <div className="presentation">
            <DomainesDInterventionContent3/>
        </div>
        <div className="presentation">
        <DomainesDInterventionContent4/>
        </div>
        <div className="presentation">
        <DomainesDInterventionContent5/>
        </div>
        </div>
      </div>
)};

function PresentationTitle(){return <>PRESENTATION</>}
function PresentationBloc1(){return <>Maître Antoine CHAUDEY a prêté serment en 2014, à l’issue d’une formation au sein de la Faculté de droit de l’Université de Lille 2. Il est titulaire d’un master de droit privé et sciences criminelles. <br/> <br/>Après avoir collaboré pendant huit ans auprès de Maître Gildas BROCHEN, il s’est installé à ses côtés en janvier 2023. Inscrit au Barreau de LILLE, il exerce en droit pénal.</>}
function PresentationBloc2(){
  return (<>Il est lauréat du premier prix du <a href="https://www.youtube.com/watch?v=lrO6dL-76i4">
  concours international de plaidoirie pour les droits de l’Homme du Mémorial de CAEN en 2018.
  </a></>)};
function PresentationBloc3(){
  return(<>
  Antoine CHAUDEY est aussi membre du Conseil syndical et Président de la section lilloise du <a href="https://lesaf.org/">Syndicat des Avocats de France</a> depuis janvier 2021. 
  <br/>
  <br/>
  Il est actuellement coordinateur pénal du Barreau de LILLE, et délégué du Bâtonnier en charge des lieux de détention. 
  </>
  )}

function Presentation(props){
  return (
    <div className="presentationStickyContainer">
    <div className="presentationTitleContainer">
        <BulletTitle/> 
        <Title isDesktop={props.isDesktop}>
          <PresentationTitle/>
        </Title>
    </div>
    <div className="presentationContainer">
      <Bloc1 isDesktop={props.isDesktop}>
        <PresentationBloc1/>
      </Bloc1>
      <Bloc2 isDesktop={props.isDesktop}>
          <PresentationBloc2/>
      </Bloc2>
      <Bloc1 isDesktop={props.isDesktop}>
        <PresentationBloc3/>
      </Bloc1>
    </div>
    </div>
)};

function PresentationDesktop(props){
  return (
    <div className="presentationStickyContainer">
      <div className='presentationRowContainer'>
        <div className='presentationDesktop'>
          <div className="presentationTitleContainerDesktop">
              <Title isDesktop={props.isDesktop}><PresentationTitle/></Title>
              <Bloc1 isDesktop={props.isDesktop}><PresentationBloc1/></Bloc1>
          </div>
        </div>
          <Bloc2 isDesktop={props.isDesktop}>
              <PresentationBloc2/>
          </Bloc2>
          <div className='videoPresentation presentationDesktop'>
            <Bloc1 isDesktop={props.isDesktop}>
              <PresentationBloc3/>
            </Bloc1>
            <VideoContainer  isDesktop={props.isDesktop}/>
          </div>
        </div>
    </div>
)
}

function Title(props) { return (
  <div className={!props.isDesktop ? "presentationTitle" : "presentationTitleDesktop"}>
    {props.children}
</div>)};

function SubTitle(props) { return (
  <div className={!props.isDesktop ? "subTitle" : "subTitleDesktop"}>
    {props.children}
</div>)};

function Bloc1(props) { return (
  <div className={!props.isDesktop ? "presentation" : "fontPresentation"}>
    {props.children}
</div>)};

function Bloc2(props) { return (
  <div className={!props.isDesktop ? "presentation" : "fontPresentation presentationDesktop"}>
    {props.children}
</div>)};


const BulletTitle = () => {return <div className='bulletTitleContainer'>
<div className='bulletTitle'></div>
</div>};

function VideoContainer(props) {return (
<div className="videoContainerBackground">
    <div className="videoContainer">
      <iframe height={!props.isDesktop ? "300" : "250"} width={!props.isDesktop ? "100%" : "540px"} src="https://www.youtube.com/embed/lrO6dL-76i4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
</div>
)};